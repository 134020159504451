// @ts-nocheck
import { Link } from "@remix-run/react";
import SocialIcons from "./SocialIcons";
import styles from "./PsyonixLogo.module.scss";
import { getDictionary } from "~/utils/dictionaries";

export default function Footer({ locale, config, dict }): JSX.Element {
  const footerLinks = config.links;
  const halfway = Math.floor(footerLinks.length / 2);
  const col1 = footerLinks.slice(0, halfway);
  const col2 = footerLinks.slice(halfway, footerLinks.length);

  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <footer className="footer" style={{ overflow: "hidden" }}>
        <div className="columns is-mobile is-multiline ooter-bottom">
          <div className="column has-text-centered is-is-one-third-mobile is-is-one-quarter-tablet is-one-fifth-desktop">
            <a className="py-0" href="/">
              <img
                className="px-6"
                src="/images/RLS_Logo_Final_RGB.png"
                alt="Rocket League Sideswipe"
                width="200"
              />
            </a>
          </div>
          <div className="column has-text-left is-full-mobile is-four-fifths-tablet is-two-fifths-desktop is-two-fifths-widescreen">
            <SocialIcons />
          </div>
          <div className="column has-text-right is-full-mobile is-four-fifths-tablet is-two-fifths-desktop is-three-fifths-widescreen"></div>
        </div>

        <div className="columns is-mobile footer-middle">
          <div className="column is-half-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
            {col1.map((navLink) => (
              <Link
                key={navLink.href}
                to={navLink.href}
                className={`navbar-item has-text-light is-capitalized`}
              >
                {navLink.title}
              </Link>
            ))}
          </div>
          <div className="column is-half-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
            {col2.map((navLink) => (
              <Link
                key={navLink.href}
                to={navLink.href}
                className={`navbar-item has-text-light is-capitalized`}
              >
                {navLink.title}
              </Link>
            ))}
          </div>
        </div>

        <div className="columns is-mobile is-multiline footer-bottom">
          <div className="column is-full-mobile is-full-tablet is-two-fifths-desktop">
            <div className={styles.psylogo}>
              <div className={styles.eyelogo}>
                <div className={styles.eye}>
                  <div className={styles.iris}>
                    <div className={styles.pupil}>
                      <div className={styles.shine}></div>
                    </div>
                  </div>
                  <div className={styles.lids}></div>
                </div>
              </div>
            </div>

            <p>
              © {year} {dict.copyright.notice}
            </p>
          </div>
          <div className="column has-text-centered is-full-mobile is-full-tablet is-three-fifths-desktop"></div>
        </div>
      </footer>
    </>
  );
}
