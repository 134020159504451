// @ts-nocheck
// this is annoying
export const fixLineHeight = (): any => {
  document.querySelectorAll(".news-tile h3").forEach((t, index) => {
    const lh = getComputedStyle(t).lineHeight;
    const lineHeightParsed = parseInt(lh.split("px")[0]);
    const amountOfLinesTilAdjust = 2;

    if (t.offsetHeight >= lineHeightParsed * amountOfLinesTilAdjust) {
      t.classList.remove("wrapped-title");
      t.classList.remove("one-line-title");
      t.classList.add("wrapped-title");
    } else {
      t.classList.remove("wrapped-title");
      t.classList.remove("one-line-title");
      t.classList.add("one-line-title");
    }
  });
};
