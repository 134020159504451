// https://gist.github.com/ryanflorence/ec1849c6d690cfbffcb408ecd633e069
import type {
  LoaderFunction,
  MetaDescriptor,
  MetaFunction,
} from "@remix-run/cloudflare";
import type { MetaArgs } from "@remix-run/react";

export const mergeMeta = <
  Loader extends LoaderFunction | unknown = unknown,
  ParentsLoaders extends Record<string, LoaderFunction> = {},
>(
  overrideFn: MetaFunction<Loader, ParentsLoaders>,
): MetaFunction<Loader, ParentsLoaders> => {
  return (argument: MetaArgs<Loader, ParentsLoaders>): MetaDescriptor[] => {
    // get meta from parent routes
    const mergedMeta = argument.matches.reduce(
      (acc: MetaDescriptor[], match: { meta: MetaDescriptor[] }) => {
        return acc.concat(match.meta || []);
      },
      [] as MetaDescriptor[],
    );

    // replace any parent meta with the same name or property with the
    // override, or append if new
    const overrides = overrideFn(argument);
    for (const override of overrides) {
      const index = mergedMeta.findIndex(
        (meta: MetaDescriptor) =>
          ("name" in meta &&
            "name" in override &&
            meta.name === override.name) ||
          ("property" in meta &&
            "property" in override &&
            meta.property === override.property) ||
          ("title" in meta && "title" in override),
      );
      if (index !== -1) {
        mergedMeta.splice(index, 1, override);
      } else {
        mergedMeta.push(override);
      }
    }

    return mergedMeta;
  };
};
