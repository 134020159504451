import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { NotFound } from "~/components/NotFound";
import LoadingAnimation from "./components/LoadingAnimation";
import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import OneTrustBanner from "./components/OneTrust";
import TrackingJs from "./components/TrackingJs";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigation,
  useParams,
} from "@remix-run/react";

import { LoaderFunctionArgs } from "@remix-run/cloudflare";
import GlobalContextProvider from "./routes/$lang._index/components/context/GlobalContext";
import Navbar from "./routes/$lang._index/components/header/Navbar";
import Footer from "./routes/$lang._index/components/footer/Footer";
import { getSdk } from "./generated/schema.graphcms";
import { getGraphCms } from "./services/graphcms";
import { Locale } from "./i18n.config";
import { localeTokenMap } from "./utils/localeTokenMap";
import { json } from "@remix-run/cloudflare";

import typography from "./typography.css?url";
import global from "./globals.scss?url";
import bulma from "./bulma.css?url";
import { LinksFunction } from "@remix-run/cloudflare";
import { AlternateHrefLang } from "./components/AlternateHrefLang";
import { getDictionary } from "./utils/dictionaries";
// import LoadingAnimation from "./components/LoadingAnimation";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: bulma },
  { rel: "stylesheet", href: global },
  { rel: "stylesheet", href: typography },
];

export function Layout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <AlternateHrefLang pathname={pathname} />
      </head>
      <body
        className={`rl-sideswipe max-width`}
        style={{ overflowX: "hidden" }}
      >
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  const { navdata, dict } = useLoaderData<typeof loader>();
  const { lang: locale } = useParams();
  const { state } = useNavigation();

  return (
    <GlobalContextProvider locale={locale}>
      <Navbar config={navdata?.header} />
      {state === "loading" ? <LoadingAnimation /> : <Outlet />}
      <Footer locale={locale} config={navdata?.footer} dict={dict} />
      <TrackingJs />
      <OneTrustBanner />
    </GlobalContextProvider>
  );
}

export default withSentry(App);

async function getNavData(env: Env, mappedLocaleToken: string) {
  const { GetNavigation } = getSdk(getGraphCms(env));
  const globalPageData = await GetNavigation({
    locale: [mappedLocaleToken, "en"] as Locale[],
    appId: "sideswipe",
  });

  return globalPageData;
}

export async function loader({ params, context }: LoaderFunctionArgs) {
  const { env } = context.cloudflare;
  const locale = params.lang as string;
  const mappedLocaleToken = localeTokenMap(locale);

  const navdata = await getNavData(env, mappedLocaleToken);

  const dict = await getDictionary(locale);
  return json({
    navdata,
    dict,
  });
}

export function ErrorBoundary() {
  const error = useRouteError();
  console.log(error);

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFound />;
  }

  captureRemixErrorBoundaryError(error);

  return (
    <div>
      <h1>Oops!</h1>
    </div>
  );
}
