const Apple: any = {
  de: "/images/store_buttons/apple/App_Store_Badge_DE_RGB_blk_092917.png",
  en: "/images/store_buttons/apple/App_Store_Badge_US-UK_RGB_blk_092917.png",
  es_ES: "/images/store_buttons/apple/App_Store_Badge_ES_RGB_blk_100217.png",
  es_MX: "/images/store_buttons/apple/App_Store_Badge_ESMX_RGB_blk_100217.png",
  "es-ES": "/images/store_buttons/apple/App_Store_Badge_ES_RGB_blk_100217.png",
  "es-MX":
    "/images/store_buttons/apple/App_Store_Badge_ESMX_RGB_blk_100217.png",
  "es-es": "/images/store_buttons/apple/App_Store_Badge_ES_RGB_blk_100217.png",
  "es-mx":
    "/images/store_buttons/apple/App_Store_Badge_ESMX_RGB_blk_100217.png",
  fr: "/images/store_buttons/apple/App_Store_Badge_FR_RGB_blk_100517.png",
  it: "/images/store_buttons/apple/App_Store_Badge_IT_RGB_blk_100317.png",
  pt_BR: "/images/store_buttons/apple/App_Store_Badge_PTBR_RGB_blk_092917.png",
  "pt-BR":
    "/images/store_buttons/apple/App_Store_Badge_PTBR_RGB_blk_092917.png",
  "pt-br":
    "/images/store_buttons/apple/App_Store_Badge_PTBR_RGB_blk_092917.png",
};

const Epic: any = {
  de: "/images/store_buttons/epic/DE_Store_Button_Prim_Dark.png",
  en: "/images/store_buttons/epic/EN_EG_Store_Button_Prim_Dark.r1hytL7W3.png",
  es_ES: "/images/store_buttons/epic/ES-ES_Store_Button_Prim_Dark.png",
  es_MX: "/images/store_buttons/epic/ES-MX_Store_Button_Prim_Dark.png",
  "es-ES": "/images/store_buttons/epic/ES-ES_Store_Button_Prim_Dark.png",
  "es-MX": "/images/store_buttons/epic/ES-MX_Store_Button_Prim_Dark.png",
  "es-es": "/images/store_buttons/epic/ES-ES_Store_Button_Prim_Dark.png",
  "es-mx": "/images/store_buttons/epic/ES-MX_Store_Button_Prim_Dark.png",
  fr: "/images/store_buttons/epic/FR_Store_Button_Prim_Dark.png",
  it: "/images/store_buttons/epic/IT_Store_Button_Prim_Dark.png",
  pt_BR: "/images/store_buttons/epic/PT-BR_Store_Button_Prim_Dark.png",
  "pt-BR": "/images/store_buttons/epic/PT-BR_Store_Button_Prim_Dark.png",
  "pt-br": "/images/store_buttons/epic/PT-BR_Store_Button_Prim_Dark.png",
};

const Google: any = {
  de: "/images/store_buttons/google/google-play-badge_de.png",
  en: "/images/store_buttons/google/google-play-badge_en.png",
  es_ES: "/images/store_buttons/google/google-play-badge_eses.png",
  es_MX: "/images/store_buttons/google/google-play-badge_esmx.png",
  "es-ES": "/images/store_buttons/google/google-play-badge_eses.png",
  "es-MX": "/images/store_buttons/google/google-play-badge_esmx.png",
  "es-es": "/images/store_buttons/google/google-play-badge_eses.png",
  "es-mx": "/images/store_buttons/google/google-play-badge_esmx.png",
  fr: "/images/store_buttons/google/google-play-badge_fr.png",
  it: "/images/store_buttons/google/google-play-badge_it.png",
  pt_BR: "/images/store_buttons/google/google-play-badge_ptbr.png",
  "pt-BR": "/images/store_buttons/google/google-play-badge_ptbr.png",
  "pt-br": "/images/store_buttons/google/google-play-badge_ptbr.png",
};

const Samsung: any = {
  de: "/images/store_buttons/samsung/GalaxyStore_Germany-German.png",
  en: "/images/store_buttons/samsung/GalaxyStore_English.SyLyj6iFF.png",
  es_ES: "/images/store_buttons/samsung/GalaxyStore_European-Spanish.png",
  es_MX: "/images/store_buttons/samsung/GalaxyStore_Latin-Spanish.png",
  "es-ES": "/images/store_buttons/samsung/GalaxyStore_European-Spanish.png",
  "es-MX": "/images/store_buttons/samsung/GalaxyStore_Latin-Spanish.png",
  "es-es": "/images/store_buttons/samsung/GalaxyStore_European-Spanish.png",
  "es-mx": "/images/store_buttons/samsung/GalaxyStore_Latin-Spanish.png",
  fr: "/images/store_buttons/samsung/GalaxyStore_European-French.png",
  it: "/images/store_buttons/samsung/GalaxyStore_Italian.png",
  pt_BR: "/images/store_buttons/samsung/GalaxyStore_Portuguese-Brazil-.png",
  "pt-BR": "/images/store_buttons/samsung/GalaxyStore_Portuguese-Brazil-.png",
  "pt-br": "/images/store_buttons/samsung/GalaxyStore_Portuguese-Brazil-.png",
};

export const samsungButtonImage = (locale: any) => {
  return Samsung[locale];
};

export const googleButtonImage = (locale: any) => {
  return Google[locale];
};

export const epicButtonImage = (locale: any) => {
  return Epic[locale];
};

export const appleButtonImage = (locale: any) => {
  return Apple[locale];
};
